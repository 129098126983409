<template>
 <div>
   dashboard
 </div>
</template>

<script>

export default {
  name: "home",
  components: {
  },
  methods: {}
}
</script>

<style scoped lang="scss">
</style>